<template>
  <div>
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button type="success" @click="onPressNewManager('customerData')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
      <el-col :span="20" :offset="4">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header :title="'Харилцагч хариуцсан менежер нэмэх'"/>
        <el-form label-position="top" :model="customerData" :rules="customerRules" ref="customerData">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Нэр:" prop="name">
                      <el-input v-model="customerData.name" placeholder="Харилцагчийн нэр"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Ажлын хаяг:" prop="address">
                      <el-input v-model="customerData.address" placeholder="ХУД-3р хороо"></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Е-мэйл хаяг:" prop="email">
                        <el-input v-model="customerData.email" placeholder="hrm@gmail.com"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Утасны дугаар:" prop="phone">
                      <el-input v-model="customerData.phone" type="number" placeholder="77667766"></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Албан тушаал:" prop="major">
                        <el-input v-model="customerData.major" placeholder="Борлуулалтын менежер ..."></el-input>
                      </el-form-item>
                  </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
            <el-row :gutter="20">
              <el-col :span="16" class="text-right">
               <div class="border-top pt20">
                  <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                  <el-button type="success" @click="onPressNewManager('customerData', 'customerRules')"
                  >Хадгалах</el-button>
               </div>
              </el-col>
            </el-row>
        </el-form>
      </el-col>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/customer-manager-list">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import customHeader from '../../../components/common/customHeader'
export default {
  components: {
    customHeader: customHeader
  },
  data () {
    return {
      confirmLeaveForm: false,
      loading: false,
      customerData: {
        name: '',
        major: '',
        phone: '',
        email: '',
        address: ''
      },
      customerRules: {
        name: [
          { required: true, message: 'нэрээ оруулна уу', trigger: 'blur' }
        ],
        major: [
          { required: true, message: 'Албан тушаалаа оруулна уу', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Утас дугаараа оруулна уу', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Е-мэйл хаягаа оруулна уу', trigger: 'blur' }
        ],
        address: [
          { required: true, message: 'Ажлын хаягаа оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    async onPressNewManager (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          const body = this.customerData
          if (this.customerData.employee_id !== '') {
            services.createEmployee(body).then(data => {
              this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
              if (data.status === 'success') {
                this.$refs[formName].resetFields()
                this.loading = false
                this.$router.push({ name: 'customerManagerList' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.loading = false
              } else {
                return false
              }
            })
          }
        } else {
          console.log('error')
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }

}
</script>

<style></style>
